// Ionic Variables and Theming. For more info, please see:
// http://ionicframework.com/docs/theming/
 
/** Font defs **/ 

@font-face {
  font-family: 'Assistant';
  font-style: normal;
  font-weight: 300;
  font-display: swap;
  src: url(../assets/fonts/Assistant/AssistantLight.woff) format('woff');
  unicode-range: U+0000-00FF, U+0131, U+0152-0153, U+02BB-02BC, U+02C6, U+02DA, U+02DC, U+2000-206F, U+2074, U+20AC, U+2122, U+2191, U+2193, U+2212, U+2215, U+FEFF, U+FFFD;
}

@font-face {
  font-family: 'Assistant';
  font-style: normal;
  font-weight: 400;
  font-display: swap;
  src: url(../assets/fonts/Assistant/AssistantRegular.woff) format('woff');
  unicode-range: U+0000-00FF, U+0131, U+0152-0153, U+02BB-02BC, U+02C6, U+02DA, U+02DC, U+2000-206F, U+2074, U+20AC, U+2122, U+2191, U+2193, U+2212, U+2215, U+FEFF, U+FFFD;
}

@font-face {
  font-family: 'Assistant';
  font-style: normal;
  font-weight: 500;
  font-display: swap;
  src: url(../assets/fonts/Assistant/AssistantMedium.woff) format('woff');
  unicode-range: U+0000-00FF, U+0131, U+0152-0153, U+02BB-02BC, U+02C6, U+02DA, U+02DC, U+2000-206F, U+2074, U+20AC, U+2122, U+2191, U+2193, U+2212, U+2215, U+FEFF, U+FFFD;
}

@font-face {
  font-family: 'Assistant';
  font-style: normal;
  font-weight: 600;
  font-display: swap;
  src: url(../assets/fonts/Assistant/AssistantSemiBold.woff) format('woff');
  unicode-range: U+0000-00FF, U+0131, U+0152-0153, U+02BB-02BC, U+02C6, U+02DA, U+02DC, U+2000-206F, U+2074, U+20AC, U+2122, U+2191, U+2193, U+2212, U+2215, U+FEFF, U+FFFD;
}

/** Ionic CSS Variables **/
:root {
  --ion-color-primary: #0f0d29;
  --ion-color-primary-rgb: 15,13,41;
  --ion-color-primary-contrast: #ffffff;
  --ion-color-primary-contrast-rgb: 255,255,255;
  --ion-color-primary-shade: #0d0b24;
  --ion-color-primary-tint: #27253e;

  --ion-color-secondary: #00547C;
  --ion-color-secondary-rgb: 0,84,124;
  --ion-color-secondary-contrast: #ffffff;
  --ion-color-secondary-contrast-rgb: 255,255,255;
  --ion-color-secondary-shade: #004a6d;
  --ion-color-secondary-tint: #1a6589;

  --ion-color-tertiary: #7F83FF;
  --ion-color-tertiary-rgb: 127,131,255;
  --ion-color-tertiary-contrast: #000000;
  --ion-color-tertiary-contrast-rgb: 0,0,0;
  --ion-color-tertiary-shade: #7073e0;
  --ion-color-tertiary-tint: #8c8fff;

  --ion-color-success: #2dd36f;
  --ion-color-success-rgb: 45,211,111;
  --ion-color-success-contrast: #000000;
  --ion-color-success-contrast-rgb: 0,0,0;
  --ion-color-success-shade: #28ba62;
  --ion-color-success-tint: #42d77d;

  --ion-color-warning: #ffc409;
  --ion-color-warning-rgb: 255,196,9;
  --ion-color-warning-contrast: #000000;
  --ion-color-warning-contrast-rgb: 0,0,0;
  --ion-color-warning-shade: #e0ac08;
  --ion-color-warning-tint: #ffca22;

  --ion-color-danger: #eb445a;
  --ion-color-danger-rgb: 235,68,90;
  --ion-color-danger-contrast: #ffffff;
  --ion-color-danger-contrast-rgb: 255,255,255;
  --ion-color-danger-shade: #cf3c4f;
  --ion-color-danger-tint: #ed576b;

  --ion-color-dark: #383654;
  --ion-color-dark-rgb: 56,54,84;
  --ion-color-dark-contrast: #ffffff;
  --ion-color-dark-contrast-rgb: 255,255,255;
  --ion-color-dark-shade: #31304a;
  --ion-color-dark-tint: #4c4a65;

  --ion-color-medium: #ACA9BB;
  --ion-color-medium-rgb: 172,169,187;
  --ion-color-medium-contrast: #000000;
  --ion-color-medium-contrast-rgb: 0,0,0;
  --ion-color-medium-shade: #9795a5;
  --ion-color-medium-tint: #b4b2c2;

  --ion-color-light: #FAF8FF;
  --ion-color-light-rgb: 250,248,255;
  --ion-color-light-contrast: #000000;
  --ion-color-light-contrast-rgb: 0,0,0;
  --ion-color-light-shade: #dcdae0;
  --ion-color-light-tint: #fbf9ff;

  --ion-color-lightblue: #f2f9fa;
  --ion-color-lightblue-rgb: 242,249,250;
  --ion-color-lightblue-contrast: #665546;
  --ion-color-lightblue-contrast-rgb: 102,85,70;
  --ion-color-lightblue-shade: #d5dbdc;
  --ion-color-lightblue-tint: #f3fafb;

  --ion-color-blue: #005587;
  --ion-color-blue-rgb: 0,85,135;
  --ion-color-blue-contrast: #ffffff;
  --ion-color-blue-contrast-rgb: 255,255,255;
  --ion-color-blue-shade: #004b77;
  --ion-color-blue-tint: #1a6693;

  --ion-color-brand0: #f0f2fd;
  --ion-color-brand0-rgb: 240,242,253;
  --ion-color-brand0-contrast: #4a4a4a;
  --ion-color-brand0-contrast-rgb: 74,74,74;
  --ion-color-brand0-shade: #d3d5df;
  --ion-color-brand0-tint: #f2f3fd;

  --ion-color-brand1: #f3f7ff;
  --ion-color-brand1-rgb: 243,247,255;
  --ion-color-brand1-contrast: #4a4a4a;
  --ion-color-brand1-contrast-rgb: 74,74,74;
  --ion-color-brand1-shade: #d6d9e0;
  --ion-color-brand1-tint: #f4f8ff;

  --ion-color-brand2: #31b8d5;
  --ion-color-brand2-rgb: 49,184,213;
  --ion-color-brand2-contrast: #000000;
  --ion-color-brand2-contrast-rgb: 0,0,0;
  --ion-color-brand2-shade: #2ba2bb;
  --ion-color-brand2-tint: #46bfd9;

.ion-color-brand0 {
  --ion-color-base: var(--ion-color-brand0) !important;
  --ion-color-base-rgb: var(--ion-color-brand0-rgb) !important;
  --ion-color-contrast: var(--ion-color-brand0-contrast) !important;
  --ion-color-contrast-rgb: var(--ion-color-brand0-contrast-rgb) !important;
  --ion-color-shade: var(--ion-color-brand0-shade) !important;
  --ion-color-tint: var(--ion-color-brand0-tint) !important;
}

.ion-color-brand1 {
  --ion-color-base: var(--ion-color-brand1) !important;
  --ion-color-base-rgb: var(--ion-color-brand1-rgb) !important;
  --ion-color-contrast: var(--ion-color-brand1-contrast) !important;
  --ion-color-contrast-rgb: var(--ion-color-brand1-contrast-rgb) !important;
  --ion-color-shade: var(--ion-color-brand1-shade) !important;
  --ion-color-tint: var(--ion-color-brand1-tint) !important;
}

.ion-color-brand2 {
  --ion-color-base: var(--ion-color-brand2) !important;
  --ion-color-base-rgb: var(--ion-color-brand2-rgb) !important;
  --ion-color-contrast: var(--ion-color-brand2-contrast) !important;
  --ion-color-contrast-rgb: var(--ion-color-brand2-contrast-rgb) !important;
  --ion-color-shade: var(--ion-color-brand2-shade) !important;
  --ion-color-tint: var(--ion-color-brand2-tint) !important;
}

/*
 * Dark Colors
 * -------------------------------------------
 */

body.dark {
  --ion-color-primary: #428cff;
  --ion-color-primary-rgb: 66,140,255;
  --ion-color-primary-contrast: #ffffff;
  --ion-color-primary-contrast-rgb: 255,255,255;
  --ion-color-primary-shade: #3a7be0;
  --ion-color-primary-tint: #5598ff;

  --ion-color-secondary: #50c8ff;
  --ion-color-secondary-rgb: 80,200,255;
  --ion-color-secondary-contrast: #ffffff;
  --ion-color-secondary-contrast-rgb: 255,255,255;
  --ion-color-secondary-shade: #46b0e0;
  --ion-color-secondary-tint: #62ceff;

  --ion-color-tertiary: #6a64ff;
  --ion-color-tertiary-rgb: 106,100,255;
  --ion-color-tertiary-contrast: #ffffff;
  --ion-color-tertiary-contrast-rgb: 255,255,255;
  --ion-color-tertiary-shade: #5d58e0;
  --ion-color-tertiary-tint: #7974ff;

  --ion-color-success: #2fdf75;
  --ion-color-success-rgb: 47,223,117;
  --ion-color-success-contrast: #000000;
  --ion-color-success-contrast-rgb: 0,0,0;
  --ion-color-success-shade: #29c467;
  --ion-color-success-tint: #44e283;

  --ion-color-warning: #ffd534;
  --ion-color-warning-rgb: 255,213,52;
  --ion-color-warning-contrast: #000000;
  --ion-color-warning-contrast-rgb: 0,0,0;
  --ion-color-warning-shade: #e0bb2e;
  --ion-color-warning-tint: #ffd948;

  --ion-color-danger: #ff4961;
  --ion-color-danger-rgb: 255,73,97;
  --ion-color-danger-contrast: #ffffff;
  --ion-color-danger-contrast-rgb: 255,255,255;
  --ion-color-danger-shade: #e04055;
  --ion-color-danger-tint: #ff5b71;

  --ion-color-dark: #f4f5f8;
  --ion-color-dark-rgb: 244,245,248;
  --ion-color-dark-contrast: #000000;
  --ion-color-dark-contrast-rgb: 0,0,0;
  --ion-color-dark-shade: #d7d8da;
  --ion-color-dark-tint: #f5f6f9;

  --ion-color-medium: #989aa2;
  --ion-color-medium-rgb: 152,154,162;
  --ion-color-medium-contrast: #000000;
  --ion-color-medium-contrast-rgb: 0,0,0;
  --ion-color-medium-shade: #86888f;
  --ion-color-medium-tint: #a2a4ab;

  --ion-color-light: #222428;
  --ion-color-light-rgb: 34,36,40;
  --ion-color-light-contrast: #ffffff;
  --ion-color-light-contrast-rgb: 255,255,255;
  --ion-color-light-shade: #1e2023;
  --ion-color-light-tint: #383a3e;
}

/*
 * iOS Dark Theme
 * -------------------------------------------
 */

.ios body.dark {
  --ion-background-color: #000000;
  --ion-background-color-rgb: 0,0,0;

  --ion-text-color: #ffffff;
  --ion-text-color-rgb: 255,255,255;

  --ion-color-step-50: #0d0d0d;
  --ion-color-step-100: #1a1a1a;
  --ion-color-step-150: #262626;
  --ion-color-step-200: #333333;
  --ion-color-step-250: #404040;
  --ion-color-step-300: #4d4d4d;
  --ion-color-step-350: #595959;
  --ion-color-step-400: #666666;
  --ion-color-step-450: #737373;
  --ion-color-step-500: #808080;
  --ion-color-step-550: #8c8c8c;
  --ion-color-step-600: #999999;
  --ion-color-step-650: #a6a6a6;
  --ion-color-step-700: #b3b3b3;
  --ion-color-step-750: #bfbfbf;
  --ion-color-step-800: #cccccc;
  --ion-color-step-850: #d9d9d9;
  --ion-color-step-900: #e6e6e6;
  --ion-color-step-950: #f2f2f2;

  --ion-item-background: #000000;

  --ion-card-background: #1c1c1d;
}

.ios body.dark ion-modal {
  --ion-background-color: var(--ion-color-step-100);
  --ion-toolbar-background: var(--ion-color-step-150);
  --ion-toolbar-border-color: var(--ion-color-step-250);
}


/*
 * Material Design Dark Theme
 * -------------------------------------------
 */

.md body.dark {
  --ion-background-color: #121212;
  --ion-background-color-rgb: 18,18,18;

  --ion-text-color: #ffffff;
  --ion-text-color-rgb: 255,255,255;

  --ion-border-color: #222222;

  --ion-color-step-50: #1e1e1e;
  --ion-color-step-100: #2a2a2a;
  --ion-color-step-150: #363636;
  --ion-color-step-200: #414141;
  --ion-color-step-250: #4d4d4d;
  --ion-color-step-300: #595959;
  --ion-color-step-350: #656565;
  --ion-color-step-400: #717171;
  --ion-color-step-450: #7d7d7d;
  --ion-color-step-500: #898989;
  --ion-color-step-550: #949494;
  --ion-color-step-600: #a0a0a0;
  --ion-color-step-650: #acacac;
  --ion-color-step-700: #b8b8b8;
  --ion-color-step-750: #c4c4c4;
  --ion-color-step-800: #d0d0d0;
  --ion-color-step-850: #dbdbdb;
  --ion-color-step-900: #e7e7e7;
  --ion-color-step-950: #f3f3f3;

  --ion-item-background: #1e1e1e;

  --ion-toolbar-background: #1f1f1f;

  --ion-tab-bar-background: #1f1f1f;

  --ion-card-background: #1e1e1e;
}

/** OHN CSS Variables **/

  --ion-font-family-head: 'Assistant' ,'Helvetica Neue', 'Helvetica', sans-serif;
  --ion-font-family: 'Assistant' ,'Helvetica Neue', 'Helvetica', sans-serif;
  --ion-text-color: #4b4b45;
  --ion-text-color-rgb: 75,75,69;

  --ion-background-color: #ffffff;
  --ion-background-color-rgb: 255,255,255;

  --chop-color-white: #fbfbfb;
  --ohn-logo-width: 4rem;
  --ohn-seal-width: 50vw;
}